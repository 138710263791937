export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const arrayChunk = (items = [], n: number = 3) => {
  const chunks: Array<typeof items> = [];

  const array = items.slice();
  while (array.length) {
    const chunk = array.splice(0, n);
    chunks.push(chunk);
  }

  return chunks;
};

export const getYoutubeId = (value) => {
  const SRC_REGEX = /src="(.*?)"/im;

  const url = SRC_REGEX.test(value) ? value.match(SRC_REGEX)[1] : value;

  const { searchParams, pathname } = new URL(url);

  return searchParams.get('v') || pathname.replace('/embed', '').replace('/', '');
};

export const stripHTML = (html: string = '<div></div>'): string =>
  html.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '');

export const lineBreakToBr = (str: string = '', isXhtml: boolean = false): string => {
  const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';

  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

export const firstOrNull = (array: PageContent.T[]): PageContent.T => array[0];

export default isEmpty;

import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';

import Image from 'common/Image';
import Loadable from 'components/Loadable';
import OneTrustCookies from 'components/OneTrustCookies';
import { firstOrNull } from 'utils/functions';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className, pixel }) => {
  const { header, siteSettings } = useStaticQuery(graphql`
    {
      header {
        sidebar {
          url {
            url
          }
          image {
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 60)
              }
            }
            mobile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 60)
              }
            }
            tablet {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 60)
              }
            }
          }
          url {
            url
          }
        }
      }
      siteSettings {
        tagTicktoc
        tagTeads
        tagPinterest
        oneTrustCookies
      }
    }
  `);

  const tikTokPixel = pixel ? firstOrNull(pixel) : null;

  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });
  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  return (
    <div className={CSSclassNames}>
      {siteSettings && siteSettings.oneTrustCookies ? (
        <OneTrustCookies oneTrustID={siteSettings.oneTrustCookies} />
      ) : null}
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>

      <main id="main" className="layout__main">
        {children}
      </main>

      <div id="sidebar">
        {header?.sidebar?.map(({ image, url }) => (
          <a href={url.url} key={url.url} target="_blank" title={url.name} rel="noreferrer">
            <Image imageData={image} alt={image.altText} />
          </a>
        ))}
      </div>
      {siteSettings && siteSettings.tagTicktoc && (
        <script>{`!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var  i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('${siteSettings.tagTicktoc}'); ttq.page(); }(window, document, 'ttq');`}</script>
      )}

      {tikTokPixel ? (
        <Helmet>
          <script>{`!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var  i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('${siteSettings.tagTicktoc}'); ttq.page(); }(window, document, 'ttq');`}</script>
          <script>
            {`ttq.track('${tikTokPixel.nameEvent}', {
              "contents": [
                {
                  "content_id": "${tikTokPixel.contentId}",
                  "content_type": "${tikTokPixel.contentType}",
                  "content_name": "${tikTokPixel.contentName}"
                }
              ],
              "value": "${tikTokPixel.contentValue}",
              "currency": "${tikTokPixel.contentCurrency}"
            });
        `}
          </script>
        </Helmet>
      ) : (
        <></>
      )}
      {/* ebd Ticktok Tag */}

      {/* Pinterest Tag */}
      {siteSettings && siteSettings.tagPinterest ? (
        <Helmet>
          <script>
            {`!function(e){if(!window.pintrk){window.pintrk = function () {
                  window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
                  n=window.pintrk;n.queue=[],n.version="3.0";var
                  t=document.createElement("script");t.async=!0,t.src=e;var
                  r=document.getElementsByTagName("script")[0];
                  r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
                  pintrk('load', '${siteSettings.tagPinterest}', {em: '<user_email_address>'});
                  pintrk('page');`}
          </script>

          <noscript>
            {`<img height="1" width="1" style="display:none;" alt="" src="https://ct.pinterest.com/v3/?event=init&tid=${siteSettings.tagPinterest}&pd[em]=<hashed_email_address>&noscript=1" />`}
          </noscript>

          <script>
            {`pintrk('track', 'pagevisit', {
          event_id: 'eventId0001'
          });`}
          </script>
        </Helmet>
      ) : (
        <></>
      )}
      {/* end Pinterest Tag */}

      {/* The following line must be placed in the head tag */}
      {siteSettings && siteSettings.tagTeads ? (
        <Helmet>
          <script>{`window.teads_e = window.teads_e || []; window.teads_buyer_pixel_id = ${siteSettings.tagTeads};`}</script>

          <script type="text/javascript" src="https://p.teads.tv/teads-fellow.js" />

          <script>
            {`window.teads_e.push({
              conversionType: "ViewContent"
            });`}
          </script>
        </Helmet>
      ) : (
        <></>
      )}
      {/* end The following line must be placed in the head tag */}
    </div>
  );
};

export default Layout;

import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImageBreakpoint, ImageDataLike, ImageProps, ResponsiveImage } from './models';
import { getArtDirectedImages } from './utils';

const defaultBreakpoints: ImageBreakpoint[] = [
  { alias: 'mobile', media: '(max-width: 500px)' },
  { alias: 'tablet', media: '(max-width: 1024px)' },
];

const Image: FC<ImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  if (!imageData) {
    return null;
  }

  const isArtDirected = Object.keys(imageData).includes('desktop');

  const mainImage = isArtDirected
    ? getImage((imageData as ResponsiveImage).desktop)
    : getImage(imageData as ImageDataLike);

  try {
    const images = withArtDirection(
      mainImage as IGatsbyImageData,
      getArtDirectedImages(breakpoints, imageData as ResponsiveImage)
    );

    return <GatsbyImage image={images} alt={alt || ''} {...restProps} />;
  } catch (e) {
    return null;
  }
};

export const createImageWithBreakpoints =
  (breakpoints: ImageBreakpoint[]): FC<ImageProps> =>
  (props: ImageProps) =>
    <Image {...props} breakpoints={breakpoints} />;

export default Image;
